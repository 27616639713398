.timeline-div{
    /* background-color: rgb(255, 239, 194); */
    padding-top:5%;
    padding-bottom:2%;
}

/* .vertical-timeline{
    margin: 0px;
    width:100%;
}

.vertical-timeline--two-columns{
    width:100%;
}

.vertical-timeline--animate{
    width:100%;
}

.vertical-timeline.vertical-timeline--two-columns{
    margin: 0px;
    width:100%;
} */