.contactus{
    width:100%;
    /* height:100vh; */
    background-image: url('../img/cbg.jpeg');
    background-position: 0% 100%;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
}

a:link {
    color: #0077b6;
    text-decoration: none;
    }    

a:visited {
    color: #0077b6;
    text-decoration: none;
    }    

a:hover {
    color: #fb8500;
    }

.social-media-icons {
    font-size: 100px; /* Adjust the font size as needed */
    margin-right: 100px; /* Adjust the margin to increase the gap between icons */
}

.social-media-icons:last-child {
    margin-right: 0; /* Remove the right margin for the last icon to avoid extra space */
}