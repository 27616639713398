.services{
    background:#FFF;

    /* background-image: url('../img/bg-services.png'); */
    
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover; 
    /* padding: 50px 0; */
    padding-bottom: 0%;
    text-align: center;
    color:#2D1671;
    padding-top:3%;
    width: 100%;
    /* height: 95vh; */

}

.service-card{
    background-size:cover;
    /* display: flex; */
    align-items: center;
    overflow:hidden;
    min-width: 300px;
    justify-content: center;
    /* display:flex; */
    /* align-items: center; */
    /* transition: 2s; */
    /* filter:brightness(0.5); */
}

.service-card:hover{
    background-size:cover;
}