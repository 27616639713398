.approach-card{
    text-align: left;
    width: 100%;
    background:#fff;
    color:#000;
    border: 0px;
    border-radius: 25px;
    overflow: hidden;
    /* padding-bottom: 4%; */
}

.right-card2{
    background-image: url('../img/p3.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* height:70vh; */
    /* position:relative; */
    width:100%;
}

.approach-card-content{
    display:flex;
}

.approach-div{
    padding-bottom:4%;
}

.approach-content{
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .right-card2{
        display:none;
    }
    
    .approach-title{
        text-align: center;
    }
}
