/* university-logos.css */
.university-logos-container {
  width: 67.5%; /* Set a specific width for the container matching other sections */
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 equal columns */
  gap: 2vw; /* Adjust the gap between the columns as needed */
  justify-content: center;
  background: #ffffff;
  padding: 5vw; /* Adjust padding using viewport width units for responsiveness */
  border-radius: 15px;
  margin: 0vw auto; /* Center the container horizontally and add margin to the top and bottom using viewport width units */
}

.UniversityLogos {
  text-align: center;
}

.university-logo {
  width: 100%; /* Ensure logos fit within their grid cell */
  height: auto; /* Maintain aspect ratio */
  max-width: 300px; /* Set a maximum width for the logos */
  max-height: 150px; /* Set a maximum height for the logos */
  object-fit: contain; /* Ensure the entire image is visible within the bounds */
  padding-inline: 1.5vw;
}
