.mission-div{
    width:100%;
    padding-top:4%;
    background-color: #8ecae6;
    padding-bottom:4%;
}

.mission-card{
    text-align: left;
    width: 100%;
    background: #fff;
    color: #000;
    border: 0px;
    border-radius: 25px;
    overflow: hidden;
}

.mission-card-content{
    display: flex;
    flex-direction: row;
}
.right-card1{
    background-image: url('../img/p1.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
}

@media screen and (max-width: 1000px) {
    .right-card1{
        display:none;
    }
    
    .mission-title{
        text-align: center;
    }
}