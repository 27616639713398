
.currevent-container{
    text-align:center;
    padding-bottom:3%;
    font-size:1rem;
    opacity:1;
}

.currevent-section-title{

    font-size: min(4rem,7vw);
    color:#FFFFFF;
    font-weight:900;
}

.currevent-card{
    text-align:left;
    width:100%;
    padding:3%;
    background:#fff;
    color:#000;
    border:0px;
    border-radius:25px;
}

.currevent-card-title{

    color:#fb8500;
    font-weight:900;
    font-size: min(5vw,1.5rem);

}


@media screen and (max-width: 768px) {
    .currevent-card-title{
        padding-top:7%;
        text-align: center;
    }
}