.card-container{
    border:0px #bbb solid;
    background: white;
    color: black;
    border-radius: 25px;
    overflow: hidden;
    max-width: 18.75rem;
    text-align: center;
    min-width: 18.75rem;
    margin-inline: 0.5rem;
    margin-top:1rem;
}

.card-img{
    height:18.75rem;
    background-size:cover;
    background-position: center;
}


.card-title{
    margin-top: 1rem;
}

.crd-txt{
    padding-left: 1rem;
    padding-right: 1rem;
}