.leaders-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
}

@media screen and (max-width: 1400px){
    .leaders-container{
        flex-direction: row;
        justify-content: center;
    }
}