.right-card{
    background-image: url('../img/p1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
}

.donate-card-content{
    display: flex;
}

.donate-content{
    width: 100%;
}

button:hover{
    background:#ffb703 !important;
}


@media screen and (max-width: 1000px) {
    .right-card{
        display: none;
    }
}