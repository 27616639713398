.card-content{
    background: rgba(0,0,0,0.6);
    /* height:100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    /* padding-top:22%; */
    /* align-self: center; */
    width:100%;
}