.newsitem{
    background: rgba(0,0,0,0.6);
    /* height:100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    /* padding-top:22%; */
    /* align-self: center; */
    width:100%;
}

.news-item-container{
    width:100%;
    cursor:pointer;
    border-radius:25px;
    background-color:#fff;
    overflow:hidden; 
    display: flex;
    transition: 0.1s;
}

.news-item-container:hover{
    transform: scale(1.03);
}


.news-content{
    padding:4rem 2rem;
    width:250%;
}


.news-img{
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

@media screen and (max-width:1000px) {
    .news-item-container{
        display: block;
    }

    .news-img{
        height:30vh;
    }

    .news-content{
        width: 100%;
    }
}