.prog-button-div{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .prog-button-div{
        justify-content: space-evenly;
        
    }
}