.testimony{
    width:100%;
    text-align: center;
}

.carousel-card{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.carousel-img{
    width: auto; 
    /* height: 7em; */
    border: '1px #eee solid'; 
    /* margin-left: '3%'; */
}

.carousel-title{
    text-align: left;
}

.carousel-subtitle{
    text-align: left;
}

.carousel-body{ 
    /* padding-top: 4%;  */
    padding-left: 3%;
}


@media screen and (max-width: 768px){
    .carousel-card{
        display: inline-block;
        justify-content: center;
    }

    .carousel-title{
        /* width:100%; */
        text-align: center;
    }

    .carousel-subtitle{
        /* width:100%; */
        text-align: center;
    }

    .carousel-img{
        text-align: center;
        padding-top:3%;
    }
}