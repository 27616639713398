.scrollcard-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 1rem;
    padding-bottom: 3rem;
    align-items: center;
}

.scrollcard-title{
    width:20%;
    text-align: left;
    font-size: min(7vw,3.2rem);
    color: white;
    font-weight: bold;
}

.scrollcard-cards{
    display:flex;
    overflow-x: scroll;
    width:70%;
    padding-bottom: 1%;
}

body::-webkit-scrollbar{
    width:0rem;
}

.scrollcard-cards::-webkit-scrollbar{
    width:1rem;
    height:0.6rem;
}

.scrollcard-cards::-webkit-scrollbar-track{
    background: #eee;
    border-radius: 100vw;
    /* margin-block:0.3rem; */
    /* margin-inline: 5%; */
    /* padding:10%; */
}
.scrollcard-cards::-webkit-scrollbar-thumb{
    background: #aaa;
    border:#ddd solid 0px;
    border-radius: 100vw;
}
.scrollcard-cards::-webkit-scrollbar-thumb:hover{
    background: #666;
    border:#ddd solid 0px;
    border-radius: 100vw;
}

@supports (scrollbar-color: #aaa #eee){
    .scrollcard-cards::-webkit-scrollbar{
        scrollbar-color: #ff0000 #4c00ff;
        scrollbar-width: thin;
    }
}

@media screen and (max-width: 1200px){
    .scrollcard-container{
        display: block;
        padding: 5% 5%;
    }
    
    .scrollcard-cards{
        width:100%;
        
    }

    .scrollcard-title{
        text-align: center;
        width: 100%;
    }
}
