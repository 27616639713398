.landing{
    background:#aaa;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('../../../AboutUs/src/img/bg-au.jpg');
    background-position: center, 0vh 0vh;

    background-repeat: no-repeat;
    background-size: cover; 
    padding-bottom: 0%;
    text-align: center;
    color:#2D1671;
    padding-top:0%;
    width: 100%;
}

.landing-card{
    background-size:cover;
    /* display: flex; */
    align-items: center;
    overflow:hidden;
    min-width: 300px;
    justify-content: center;
    /* display:flex; */
    /* align-items: center; */
    /* transition: 2s; */
    /* filter:brightness(0.5); */
}

.service-card:hover{
    background-size:cover;
}