.prog-button-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .prog-button-div{
        justify-content: space-evenly;
        
    }
}

.card-body .numbered-list {
    list-style-type: disc; /* Use bullet points */
  }

.underline {
    text-decoration: underline; /* Underline the text */
  }

.numbered-list li {
    margin-bottom: 10px; /* Adjust the value as needed */
  }
  

.social-media-icons {
    font-size: 50px; /* Adjust the font size as needed */
    margin-right: 40px; /* Adjust the margin to increase the gap between icons */
}

.social-media-icons:last-child {
    margin-right: 0; /* Remove the right margin for the last icon to avoid extra space */
}