.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 1rem; */
    padding-block: 0.5rem;
}

.nav-logo{
    display: flex;
    align-items: center;
}

.nav-logo-title{
    font-weight: 900;
    color: white;
}

.nav-list{
    display: flex;
    font-size: 18px;
    align-items: center;
}

.nav-list-dropdown-content{
    display: none;
    position: absolute;
    /* border: 2px solid black; */
    top:50%;
    text-align: left;
}

.nav-list-dropdown-item:hover{
    color: #fb8500 !important;
}

.nav-list-a:hover,.nav-list-dropdown-title:hover{
    color: #fb8500 !important;
}

.nav-list-dropdown:hover>.nav-list-dropdown-content{
    display: block;
}


li{
    list-style: none;
}

a{
    text-decoration: none;
}

.nav-list-item{
    padding: 0 0.75em;
}

.hamburger-icon{
    display: none;
    font-size: 1.5rem;
    border: 2px white solid;
    padding-inline: 0.5rem;
    border-radius: 0.5rem;
}

.nav-list2{
    display: none;
}

@media screen and (max-width: 1000px){

    .nav-list{
        display: none;
    }

    .hamburger-icon{
        display: block;
    }

    .nav-bar{
        position:relative;
    }

    .nav-list2.responsive{
        transition: 2s;
        display: block;
        background: #fff;
    }

    .nav-list2.responsive li{
        padding-block:0.5rem;
    }

    .nav-list2.responsive a{
        color: #000;
    }

    .nav-list2.responsive .nav-list-dropdown{
        display:inline-block;
    }

    .nav-list2.responsive .nav-list-dropdown-content{
        display: none;
        overflow: hidden;
        position:static;
        text-align: left;
    }

    .nav-list2.responsive .nav-list-dropdown:hover > .nav-list-dropdown-content{
        display: block;

    }

    .nav-list2.responsive>.nav-list-dropdown{
        display:block;
    }
    
    .nav-list-dropdown-content> div{
        /* float:right; */
        display: block;
        padding-block: 0.5rem;
        padding-left:1rem
    }

}