.editorial{
    transform: scaleX(1);
    width:100%;
    max-height: 15vh;
    /* z-index: -1; */
    /* position:absolute; */
    /* transform:translate(0,0%); */
    /* bottom:0; */
    left:0;
    right:0;

    /* left:50%; */
}

.uni-ico{
    position:absolute;
    /* bottom:0; */
    /* top:90%; */
    /* width:100%; */
    /* z-i */
    /* transform: translate(0, 10px) scale(0.6); */
    top:85%;
    animation:ride 16s linear infinite;
    animation-delay: 1s;
    /* left:0; */
}

@keyframes ride{
    0% {transform: translate(0px , 10px) scale(0.6)}
    50%{transform: translate(0px , -100px) scale(0.6)}
    100% {transform: translate(0px , 10px) scale(0.6)} 
}

.parallax > use{
    animation:move-forever 12s linear infinite;
}
.parallax > use:nth-child(1){
      animation-delay:-2s;
      animation-duration:12s;
      opacity:0.75;
    }

.parallax>use:nth-child(2) {
      animation-delay:-1s;
      animation-duration:14s;
      opacity:0.8;
}
.parallax>use:nth-child(3) {
      animation-delay:-3s;
      animation-duration:16s;
    }
    .parallax>use:nth-child(4) {
      animation-delay:-1.5s;
      animation-duration:10s;
      opacity:0.75;
    }
  
  @keyframes move-forever{
    0% {transform: translate(-90px , 0%)}
    100% {transform: translate(85px , 0%)} 
  }
