.modal-content{
    border-radius: 26px;
}

.modal-header{
    background:#050A30;
    border-radius:25px 25px 0 0
}

.howdonate-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.howdonate-card{
    max-width: 400px;
    margin-block:0.5rem;
    width: 100%;
    cursor: pointer;
    border-radius: 25px;
    border:0px;
    overflow: hidden;
    margin-inline:0.5rem;
    transition: 0.1s;
}

.howdonate-card:hover{
    transform: scale(1.07);
}



.howdonate-modal-image{
    width: 40%;
    aspect-ratio: 1 / 1;
    height: auto;
    margin-inline: 2%;
    margin-block:2%;
    background-size: cover;
    border-radius: 100%;
}

.howdonate-modal-content{
    width:100%;
}

@media screen and (max-width: 1200px) {
    .howdonate-modal-image{
        display: none;   
    }    
}