.terms{
    padding-top: 5%;
    padding-bottom: 5%;
}

.accordion{
    --bs-accordion-border-radius: 25px;
    --bs-accordion-inner-border-radius: 25px;
}

.apply-container{
    background-color: white;
    padding:5em;
    text-align: justify;
    border-radius: 25px;
}