.vision-card{
    text-align: left;
    width: 100%;
    background: #fb8500;
    border: 0px;
    border-radius: 25px;
    overflow: hidden;
}

.vision-div{
    padding-bottom: 4%;
}

.left-card1{
    background-image: url('../img/p2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width:100%;
}


.vision-content{
    padding:2rem 2rem;
    width: 100%;
}

.vision-card-content{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1000px) {
    .left-card1{
        display:none;
    }
    
    .vision-title{
        text-align: center;
    }
}