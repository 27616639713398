
.story-card{
    text-align: left;
    width: 100%;
    background:#fb8500;
    color:#000;
    border: 0px;
    border-radius: 25px;
    overflow: hidden;
}

.left-card2{
    background-image: url('../img/os.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
}

.story-card-content{
    display:flex;
}

.story-content{
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .left-card2{
        display:none;
    }
    .story-title{
        text-align: center;
    }
}
