.main-container{
    margin-top: 20%;
    overflow: auto;
}

.left2{
    border-right: 3px #fff solid;
    /* padding-bottom: 3%; */
    padding-right: 2%;
    /* padding-top: 2%; */
}

.button1{
    font-size: min(1.5rem,3.3vw);
    border-radius: 25px; 
    border: 0; 
    color:black;
    background:rgba(255,255,255,0.7);
    padding-inline: 15px;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: max-content;
    transition:0.1s ease;
}

.button1:hover{
    background:rgba(255, 183, 3);
    color:white;
}

.button2{
    font-size: min(1.5rem,3.3vw);
    border-radius: 25px; 
    border: 0; 
    color:white;
    background:rgba(5,10,48,0.7);
    padding-inline: 15px;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: absolute;
    transition:0.1s ease;
}

.button2:hover{
    background:rgba(255, 183, 3);
}