
.uws-images-container {
  width: 50%; /* Set a specific width for the container matching other sections */
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: transparent; 
  padding: 0vw; /* Adjust padding using viewport width units for responsiveness */
  border-radius: 15px;
  margin: 0vw auto; /* Center the container horizontally and add margin to the top and bottom using viewport width units */
}

.UWSImages {
  text-align: center;
}

.uws-imag {
  height: 30vw;
  padding-inline:0.2vw;
}
