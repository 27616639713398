.main-container-AU{
    margin-top: 10%;
    overflow: auto;
}

.left2{
    border-right: 3px #fff solid;
    /* padding-bottom: 3%; */
    padding-right: 2%;
    /* padding-top: 2%; */
}

